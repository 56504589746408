import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Navbar from 'react-bootstrap/Navbar'
import "../styles/layout.scss"
import logo from "../images/logos/retailStack-logo.svg"
import {isMobile} from 'react-device-detect';

class Header extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      activeDropdown: '',
      dropdowns: {},
      scrollNav: false
    };
    this.onScroll = this.onScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll, false);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll, false);
  }

  onScroll = (e) => {
    const s = window.scrollY < 120 ? false : true;

    this.setState({ scrollNav: s });
  }

  dropdownOpen = (key) => {
    return isMobile ? false : this.setState({activeDropdown: key});
  }

  dropdownClose = () => {
    this.setState({activeDropdown: ''});
  }

  mobileDropdown(key) {
    const k = this.state.activeDropdown !== key ? key : '';
    this.setState({activeDropdown: k});
  }

  render() {

    return (
      <header>
        {/* <nav className="navbar navbar-expand-lg"> */}
        <Navbar fixed="top" collapseOnSelect expand="lg" className={ this.state.scrollNav ? 'scrollNav' : '' }>
          <div className="container">
            <Link to="/">
              <span className="navbar-brand">
                <img src={logo} alt="RetailStack Logo" />
              </span>
            </Link>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
            {/* <div id="navbarNav" className={"collapse navbar-collapse " + (this.state.showMobileNav ? 'show' : '')}> */}
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <Link to="/" className="nav-link" exact activeClassName="active">Home</Link>
                </li>
                <li className="nav-item">
                  <Link to="/platform" className="nav-link" exact activeClassName="active">Platform</Link>
                </li>
                <li className="nav-item has-dropdown"
                  onMouseEnter={() => this.dropdownOpen('industries')}
                  onMouseLeave={ this.dropdownClose }>
                  <Link 
                    to="/industries" 
                    className="nav-link" 
                    activeClassName="active"
                    partiallyActive={true}
                  >
                    Industries <span className="mobile-dd-btn" onClick={(e) => this.mobileDropdown('industries')}>+</span>
                  </Link>
                    <div className="navbar-dropdown" style={{display: this.state.activeDropdown === 'industries' ? 'block' : 'none'}}>
                      <ul>
                        <li>
                          <Link to="/industries/retail" exact activeClassName="active">Retail</Link>
                        </li>
                        <li>
                          <Link to="/industries/c-store" exact activeClassName="active">C-Store</Link>
                        </li>
                        <li>
                          <Link to="/industries/restaurant" exact activeClassName="active">Restaurant</Link>
                        </li>
                        <li>
                          <Link to="/industries/enterprise" exact activeClassName="active">Enterprise</Link>
                        </li>
                      </ul>
                    </div>
                </li>
                <li className="nav-item">
                  <Link to="/news" className="nav-link" exact activeClassName="active">News</Link>
                </li>
                <li className="nav-item">
                  <Link to="/contact" className="nav-link" exact activeClassName="active">Contact</Link>
                </li>
              </ul>
            </Navbar.Collapse>
          </div>
        </Navbar>
      </header>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
