import React from "react"
import styles from './heading.module.scss';

const Heading = ({ styleKey, secondary, title, copy, align, white, marginBottom }) => {
  let selectedStyle = styles.primary;
  if (styleKey === "secondary") {
    selectedStyle = styles.secondary;
  }
  if (styleKey === "tertiary") {
    selectedStyle = styles.tertiary;
  }

  return (
    <div 
      className={`${styles.heading} ${selectedStyle} ` + (align ? styles.center : styles.left) + " " + (white ? styles.white : null)}
      style={{ marginBottom: marginBottom > 0 ? marginBottom : 70 }}>
      <h1>{title}</h1>
      {
        copy ? <p>{ copy }</p> : ''
      }
    </div>
  )
}

export default Heading