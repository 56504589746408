import React from "react"
import logo from "../images/logos/retailStack-logo-white.svg"
import { Link } from "gatsby"
import MapMarker from "../images/mapMarker.inline.svg"
import Phone from "../images/phone.inline.svg"
import Email from "../images/email.inline.svg"
import FooterLogo from "../images/logos/footer-retailStack-logo.inline.svg"
import SocialLinks from "./social-links"

class Footer extends React.Component {
  render() {
    return (
      <footer>
        <FooterLogo className="logo-icon" />
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-7 getInTouch">
              <h1>Get in touch</h1>
              <p>
                Ready to learn how the RetailStack Commerce Platform can help
                you out pace the other guys? Contact us for a free, no
                commitment consultation.
              </p>
              <ul className=" footer-nav">
                <li className="nav-item">
                  <Link
                    to="/platform"
                    className="nav-link"
                    activeclassname="active"
                  >
                    Platform
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/industries"
                    className="nav-link"
                    activeclassname="active"
                  >
                    Industries
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/news"
                    className="nav-link"
                    activeclassname="active"
                  >
                    News
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/contact"
                    className="nav-link"
                    activeclassname="active"
                  >
                    Contact
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/privacy-policy"
                    className="nav-link"
                    activeclassname="active"
                  >
                    Privacy Policy
                  </Link>
                </li>
              </ul>
              {/* <ul className="legal-nav">
                <li className="nav-item">
                  <Link to="/legal" className="nav-link" activeclassname="active">Legal</Link>
                </li>
                <li className="nav-item">
                  <Link to="/privacy-policy" className="nav-link" activeclassname="active">Privacy Policy</Link>
                </li>
              </ul> */}
            </div>
            <div className="col-sm-12 col-md-4 offset-md-1">
              <img src={logo} alt="RetailStack Logo" className="mb-4" />

              <div className="icon-block">
                <MapMarker />
                <p>
                  <a
                    href="https://www.google.com/maps/place/234+Morrell+Rd+%23312,+Knoxville,+TN+37919/@35.9256228,-84.0336845,19z/data=!3m1!4b1!4m5!3m4!1s0x885c24b94e3e9ec9:0x3e251b64e4e4f20b!8m2!3d35.9256217!4d-84.0331373"
                    target="_blank"
                    rel="noreferrer"
                  >
                    234 Morrell Rd<br></br>
                    #312<br></br>
                    Knoxville, TN 37919
                  </a>
                </p>
              </div>

              <div className="icon-block">
                <Phone />
                <p>
                  <a href="tel:8449513037">(844) 951-3037</a>
                </p>
              </div>

              <div className="icon-block">
                <Email />
                <p>
                  <a href="mailto:info@retailstack.com">info@retailstack.com</a>
                </p>
              </div>

              <div className="social-list d-flex align-items-center">
                <SocialLinks color="#fff" />
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
